@import 'react-quill/dist/quill.snow.css';
@import 'video-react/dist/video-react.css';
@import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
@import 'react-pdf/dist/esm/Page/TextLayer.css';
.ql-editor {
    min-height: 300px !important;
}
td.google-visualization-orgchart-node div{
    /* white-space: nowrap; */
    min-width: 150px;
}
